import { GlobalAction, failure, request, success } from ".";
import { MenteeServices } from "../services";

export const GET_MENTEE_LIST_REQUEST = 'GET_MENTEE_LIST_REQUEST';
export const GET_MENTEE_LIST_SUCCESS = 'GET_MENTEE_LIST_SUCCESS';
export const GET_MENTEE_LIST_FAILURE = 'GET_MENTEE_LIST_FAILURE';

export const CHANGE_MENTEE_STATUS_REQUEST = 'CHANGE_MENTEE_STATUS_REQUEST';
export const CHANGE_MENTEE_STATUS_SUCCESS = 'CHANGE_MENTEE_STATUS_SUCCESS';
export const CHANGE_MENTEE_STATUS_FAILURE = 'CHANGE_MENTEE_STATUS_FAILURE';

export const GET_MENTEE_DETAILS_REQUEST = 'GET_MENTEE_DETAILS_REQUEST';
export const GET_MENTEE_DETAILS_SUCCESS = 'GET_MENTEE_DETAILS_SUCCESS';
export const GET_MENTEE_DETAILS_FAILURE = 'GET_MENTEE_DETAILS_FAILURE';

export const RESET_MENTEE_PROFILE_REQUEST = 'RESET_MENTEE_PROFILE_REQUEST';
export const RESET_MENTEE_PROFILE_SUCCESS = 'RESET_MENTEE_PROFILE_SUCCESS';
export const RESET_MENTEE_PROFILE_FAILURE = 'RESET_MENTEE_PROFILE_FAILURE';

export const REMOVE_MENTEE_REQUEST = 'REMOVE_MENTEE_REQUEST';
export const REMOVE_MENTEE_SUCCESS = 'REMOVE_MENTEE_SUCCESS';
export const REMOVE_MENTEE_FAILURE = 'REMOVE_MENTEE_FAILURE';

export const MenteeAction = {

    /**
     * This code block defines an action creator function `getMenteeList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getMenteeList: (params) => {
        return dispatch => {
            dispatch(request(GET_MENTEE_LIST_REQUEST, params));
            return MenteeServices.getMenteeList(params)
                .then(
                    response => { return dispatch(success(GET_MENTEE_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_MENTEE_LIST_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This code block defines an action creator function `getMenteeList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    changeMenteeStatus: (params) => {
        return dispatch => {
            dispatch(request(CHANGE_MENTEE_STATUS_REQUEST, params));
            return MenteeServices.changeMenteeStatus(params)
                .then(
                    response => { return dispatch(success(CHANGE_MENTEE_STATUS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_MENTEE_STATUS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This code block defines an action creator function `getMenteeList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getMenteeDetails: (params) => {
        return dispatch => {
            dispatch(request(GET_MENTEE_DETAILS_REQUEST, params));
            return MenteeServices.getMenteeDetails(params)
                .then(
                    response => { return dispatch(success(GET_MENTEE_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_MENTEE_DETAILS_FAILURE, params, error)); }
                );
        };

    },
    /**
     * This code block defines an action creator function `getMenteeList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    resetMenteeProfile: (params) => {
        return dispatch => {
            dispatch(request(RESET_MENTEE_PROFILE_REQUEST, params));
            return MenteeServices.resetMenteeProfile(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(RESET_MENTEE_PROFILE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(RESET_MENTEE_PROFILE_FAILURE, params, error)); }
                );
        };

    },
    /**
     * This code block defines an action creator function `getMenteeList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    removeMentee: (params) => {
        return dispatch => {
            dispatch(request(REMOVE_MENTEE_REQUEST, params));
            return MenteeServices.removeMentee(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(REMOVE_MENTEE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(REMOVE_MENTEE_FAILURE, params, error)); }
                );
        };

    },

};
