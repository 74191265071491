import { Brightness4, Brightness7 } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GlobalAction, ProfileAction } from '../../../../redux/actions';

function ThemeMode() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const theme = useTheme();

    /* Destructuring the state from redux store. */
    const updateTheme = (params) => dispatch(ProfileAction.updateTheme(params));

    /**
     * This function toggles the theme mode between light and dark and updates the theme accordingly.
     * @param {null}
     * @return {json}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const changeThemeMode = () => {
        dispatch(GlobalAction.setTheme(theme.palette.mode === 'dark' ? false : true));
        updateTheme();
    };

    return (
        <Tooltip title="Change Mode">
            <IconButton onClick={changeThemeMode}>
                {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
        </Tooltip>
    );
}

export { ThemeMode };