import { apiServices } from "../../_helpers";

export const MenteeServices = {

    /**
     * `getMenteeList` is a function that sends a POST request to the `/mentees/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMenteeList` object and can be
     * used to retrieve a list of mentees.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getMenteeList: (params) => {
        return apiServices.get(`/mentees/list`, { params })
            .then((response) => { return response; });
    },
    /**
     * `getMenteeList` is a function that sends a POST request to the `/mentees/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMenteeList` object and can be
     * used to retrieve a list of mentees.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeMenteeStatus: (params) => {
        return apiServices.put(`/mentee/status/update`, params)
            .then((response) => { return response; });
    },
    /**
     * `getMenteeList` is a function that sends a POST request to the `/mentees/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMenteeList` object and can be
     * used to retrieve a list of mentees.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getMenteeDetails: (id) => {
        return apiServices.get(`/mentee/${id}`)
            .then((response) => { return response; });
    },
    /**
     * `getMenteeList` is a function that sends a POST request to the `/mentees/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMenteeList` object and can be
     * used to retrieve a list of mentees.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    resetMenteeProfile: (id) => {
        return apiServices.get(`/mentee/reset/${id}`)
            .then((response) => { return response; });
    },
    /**
     * `getMenteeList` is a function that sends a POST request to the `/mentees/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMenteeList` object and can be
     * used to retrieve a list of mentees.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    removeMentee: (id) => {
        return apiServices.delete(`/mentee/remove/${id}`)
            .then((response) => { return response; });
    },
};
