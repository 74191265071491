import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    paper: {
      padding: pxToRem(24),
    },
    paperFullScreen: {
      borderRadius: 0,
    },
  },
  variants: []
};
