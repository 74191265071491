import {
    GET_REFER_AND_EARN_LIST_REQUEST, GET_REFER_AND_EARN_LIST_SUCCESS, GET_REFER_AND_EARN_LIST_FAILURE, GET_REFER_AND_EARN_DETAILS_REQUEST, GET_REFER_AND_EARN_DETAILS_SUCCESS, GET_REFER_AND_EARN_DETAILS_FAILURE,
} from '../actions';

const initialState = {
    refer_and_earn_loading: false, refer_and_earn: [], refer_and_earn_errors: {}, refer_and_earn_total: 0, refer_and_earn_total_pages: 0, refer_and_earn_per_page: 25, refer_and_earn_page: 1,
    refer_and_earn_details_loading: false, refer_and_earn_details: [], refer_and_earn_details_errors: {}, refer_and_earn_details_total: 0, refer_and_earn_details_pages: 0, refer_and_earn_details_per_page: 25, refer_and_earn_details_page: 1,
};

export function ReferAndEarnReducer(state = initialState, action) {
    switch (action.type) {
        //  begin::Get email logs list redux
        case GET_REFER_AND_EARN_LIST_REQUEST: {
            return {
                ...state,
                refer_and_earn_loading: true,
                refer_and_earn_errors: false,
            };
        }
        case GET_REFER_AND_EARN_LIST_SUCCESS: {
            let newArray = []; //making a new array
            action.data.refer_and_earn.forEach((payload) => {
                const thirdPartyIndex = newArray.findIndex(refer_and_earn => refer_and_earn._id === payload._id);
                if (thirdPartyIndex !== -1) {
                    newArray[thirdPartyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                refer_and_earn_loading: false,
                refer_and_earn: newArray,
                refer_and_earn_errors: {},
                refer_and_earn_page: action.data.page,
                refer_and_earn_per_page: action.data.per_page,
                refer_and_earn_total: action.data.total,
                refer_and_earn_total_pages: action.data.total_pages
            };
        }
        case GET_REFER_AND_EARN_LIST_FAILURE: {
            return {
                ...state,
                refer_and_earn_loading: false,
                refer_and_earn_errors: action.errors,
            };
        }
        // begin::Get email logs list redux


        //  begin::get user redux
        case GET_REFER_AND_EARN_DETAILS_REQUEST: {
            return {
                ...state,
                refer_and_earn_details_loading: true,
                refer_and_earn_details: {}
            };
        }
        case GET_REFER_AND_EARN_DETAILS_SUCCESS: {

            let referAndEarnArray = [...state.refer_and_earn];
            const thirdPartyIndex = referAndEarnArray.findIndex(thirdParty => thirdParty._id === action.data._id);
            if (thirdPartyIndex !== -1) {
                referAndEarnArray[thirdPartyIndex] = action.data;
            } else {
                referAndEarnArray = referAndEarnArray.concat(action.data);
            }

            return {
                ...state,
                refer_and_earn_details_loading: false,
                refer_and_earn_details: action.data,
                refer_and_earn_details_errors: {},
                refer_and_earn: referAndEarnArray
            };
        }
        case GET_REFER_AND_EARN_DETAILS_FAILURE: {
            return {
                ...state,
                refer_and_earn_details_loading: false,
                refer_and_earn_details_errors: action.errors,
            };
        }
        // begin::get user redux

        default:
            return state;
    }

}
