import { AccessTime, CircleNotifications } from '@mui/icons-material';
import { Avatar, Badge, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileAction } from '../../../../redux/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GeneralButton } from '../../../../_components/controls';
import { NoData } from '../../../../_components/layout/no-data';

function Notifications() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { notifications_loading: isLoading, notifications, pending_notifications } = useSelector((state) => state.ProfileReducer);
    const getNotifications = (params) => dispatch(ProfileAction.getNotifications(params));
    const markAllRead = () => dispatch(ProfileAction.markAllRead());
    const markRead = (params) => dispatch(ProfileAction.markRead(params));

    /* Initializing the state with the default values. */
    const [payload, setPayload] = useState({ limit: 100, page: 1 });
    const [state, setState] = useState({ right: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        getNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The function `getNext` retrieves a notification list and updates the page number in the payload
     * if the result status is true.
     * @param {null}
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const getNext = async () => {
        const result = await getNotifications(payload);
        if (result.status) setPayload({ ...payload, page: payload.page + 1 });
    };

    /**
     * The function toggles the state of a drawer based on the anchor and open parameters.
     * @param {String} anchor - The `anchor` parameter is a string that specifies the position of the drawer. It
     * can be one of the following values: "left", "right", "top", or "bottom".
     * @param {Boolean} open - The `open` parameter is a boolean value that indicates whether the drawer should
     * be open or closed. If `open` is `true`, the drawer will be open, and if it is `false`, the
     * drawer will be closed.
     * @returns {null} A function is being returned that takes an event as an argument and checks if the event
     * type is 'keydown' and if the key pressed is 'Tab' or 'Shift'. If either of these conditions are
     * true, the function returns nothing. Otherwise, it sets the state of the component with the
     * 'right' property to the value of the 'open' parameter passed to the toggleDrawer function.
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setState({ right: open });
    };

    /**
     * The function calls the "markAllRead" function asynchronously to mark all notifications as read.
     * @param {null}
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const markAllReadAction = async () => {
        await markAllRead();
    };

    /**
     * This function marks a notification as read if its status is 'pending'.
     * @param {Object} notification - The `notification` parameter is an object that represents a notification.
     * It likely has properties such as `_id` (a unique identifier for the notification) and `status`
     * (the current status of the notification, which in this case is being checked to see if it is
     * "pending").
     * @returns {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const markReadAction = async (notification) => {
        if (notification.status === 'pending')
            await markRead({ notification_id: notification._id });
    };

    return (
        <Fragment>
            <Tooltip title="Notifications">
                <IconButton onClick={toggleDrawer('right', true)}>
                    <Badge badgeContent={pending_notifications} color="secondary">
                        <CircleNotifications />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}  >
                <Card sx={{ width: 450, height: `100%`, backgroundColor: `inherit`, borderRadius: 0 }} role="presentation">
                    <CardHeader title="Notifications" action={<GeneralButton label='Mark all as read' size='small' variant='text' onClick={() => markAllReadAction()} />} />
                    <CardContent sx={{ p: 0, pt: 1, height: `100%`, pb: `48px !important` }}>
                        <InfiniteScroll dataLength={notifications.length} next={getNext} hasMore={true}>
                            {
                                notifications.length > 0 && notifications.map((notification, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <Card sx={{ minHeight: `80px`, height: `100%`, backgroundColor: `inherit`, borderRadius: 0, display: `flex`, alignItems: `center`, justifyContent: `center` }} elevation={notification.status === 'read' ? 0 : 1} >
                                                <CardActionArea onClick={() => markReadAction(notification)}>
                                                    <CardContent sx={{ padding: 0 }}>
                                                        <Box sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} >
                                                            <Stack direction="row" alignItems="center" spacing={2} >
                                                                <Avatar src={notification.sender_profile_picture} />
                                                                <Box sx={{ width: `100%` }}>
                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                        <Typography variant="h6">{notification.sender_info.name}</Typography>
                                                                        <Typography variant="body2" sx={{ fontSize: 10 }}> <AccessTime /> {notification.timeAgo}</Typography>
                                                                    </Stack>
                                                                    <Typography variant="body2" sx={{ fontSize: 12 }}>{notification.description}</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                            <Divider sx={{ m: 0 }} />
                                        </Fragment>
                                    );
                                })
                            }
                            {
                                notifications.length === 0 && !isLoading && (
                                    <Box sx={{ pl: 8, pr: 8, pt: 1, pb: 1 }}>
                                        <NoData minHeight={`80vh`} content1={`No New`} content2={`Notifications`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} />
                                    </Box>
                                )
                            }
                            {isLoading && <CircularProgress />}
                        </InfiniteScroll>
                    </CardContent>
                </Card>
            </Drawer>
        </Fragment>
    );
}

export { Notifications };