import borders from "../../base/borders";
import typography from "../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      minHeight: "unset",
      borderRadius: borders.borderRadius.md,
      fontSize: typography.size.sm,
      transition: "background-color 300ms ease, color 300ms ease",
    },
  },
  variants: []
};
