import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },
  },
  variants: []
};
