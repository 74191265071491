import React from 'react';
import { Button, CircularProgress } from '@mui/material';


function DialogActionSaveBtn({ text = "Save", color = "dark", className, tabIndex, loading = false, disabled, ...props }) {
    return (
        <Button
            type="submit"
            variant="contained"
            color={color}
            tabIndex={tabIndex}
            disabled={loading || disabled}
            startIcon={loading ? (<CircularProgress size={14} />) : ('')}
            {...props}
        >
            {text}
        </Button>
    );
}



export { DialogActionSaveBtn };
