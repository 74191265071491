import React from 'react';
import { Avatar, Grid, Typography, Box, Chip, CardContent, Stack, Card } from '@mui/material';
import { AccountCircle, Email } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ProfileDetailsStyled } from '.';
import { Animate } from '../../../../_components';

function ProfileDetails() {

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ProfileDetailsStyled>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
                                    <Animate animate='right-left'>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <Avatar >{user.short_name}</Avatar>
                                        </Box>
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Animate>
                                        <Stack spacing={1}>
                                            <Typography variant="h2" color="primary">{user.name}</Typography>
                                            <Typography variant="h6"><AccountCircle />&nbsp;{user.role_text}&nbsp;|&nbsp;<Chip size='small' label={user.status_text} color={user.status_color} /></Typography>
                                            <Typography variant="body1"><Email />&nbsp;{user.email}</Typography>
                                        </Stack>
                                    </Animate>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </ProfileDetailsStyled>
            </Grid>
        </Grid>
    );
}


export { ProfileDetails };
