import pxToRem from "../functions/pxToRem";

const baseProperties = {
  fontFamily: 'Poppins, Helvetica, sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(9),
  fontSizeXS: pxToRem(10),
  fontSizeSM: pxToRem(13),
  fontSizeMD: pxToRem(14),
  fontSizeLG: pxToRem(16),
  fontSizeXL: pxToRem(18),
  lineHeightXS: 1,
  lineHeightSM: 1.25,
  lineHeightMD: 1.5,
  lineHeightLG: 1.75,
  lineHeightXL: 2,
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightMedium,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightRegular,
  lineHeight: baseProperties.lineHeightMD,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: pxToRem(40),
    lineHeight: baseProperties.lineHeightSM,
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: pxToRem(32),
    lineHeight: baseProperties.lineHeightMD,
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: pxToRem(28),
    lineHeight: baseProperties.lineHeightMD,
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: baseProperties.lineHeightMD,
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: pxToRem(20),
    lineHeight: baseProperties.lineHeightMD,
    ...baseHeadingProperties,
  },

  h6: {
    fontSize: pxToRem(16),
    lineHeight: baseProperties.lineHeightMD,
    ...baseHeadingProperties,
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightMD,
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightMD,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightLG,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightMD,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightMD,
    textTransform: "none",
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.lineHeightMD,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  d1: {
    fontSize: pxToRem(90),
    ...baseDisplayProperties,
  },

  d2: {
    fontSize: pxToRem(82.5),
    ...baseDisplayProperties,
  },

  d3: {
    fontSize: pxToRem(67.5),
    ...baseDisplayProperties,
  },

  d4: {
    fontSize: pxToRem(52.5),
    ...baseDisplayProperties,
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    "xs": baseProperties.fontSizeXS,
    "sm": baseProperties.fontSizeSM,
    "md": baseProperties.fontSizeMD,
    "lg": baseProperties.fontSizeLG,
    "xl": baseProperties.fontSizeXL,
  },

  lineHeight: {
    xs: baseProperties.lineHeightXS,
    sm: baseProperties.lineHeightSM,
    md: baseProperties.lineHeightMD,
    lg: baseProperties.lineHeightLG,
    xl: baseProperties.lineHeightXL,
  },
};

export default typography;
