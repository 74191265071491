import { failure, request, success } from ".";
import { ReferAndEarnServices } from "../services";

export const GET_REFER_AND_EARN_LIST_REQUEST = 'GET_REFER_AND_EARN_LIST_REQUEST';
export const GET_REFER_AND_EARN_LIST_SUCCESS = 'GET_REFER_AND_EARN_LIST_SUCCESS';
export const GET_REFER_AND_EARN_LIST_FAILURE = 'GET_REFER_AND_EARN_LIST_FAILURE';

export const GET_REFER_AND_EARN_DETAILS_REQUEST = 'GET_REFER_AND_EARN_DETAILS_REQUEST';
export const GET_REFER_AND_EARN_DETAILS_SUCCESS = 'GET_REFER_AND_EARN_DETAILS_SUCCESS';
export const GET_REFER_AND_EARN_DETAILS_FAILURE = 'GET_REFER_AND_EARN_DETAILS_FAILURE';

export const ReferAndEarnAction = {

    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getReferAndEarnList: (params) => {
        return dispatch => {
            dispatch(request(GET_REFER_AND_EARN_LIST_REQUEST, params));
            return ReferAndEarnServices.getReferAndEarnList(params)
                .then(
                    response => { return dispatch(success(GET_REFER_AND_EARN_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_REFER_AND_EARN_LIST_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This code block defines an action creator function `getMentorList` that dispatches an asynchronous action to get a list of email logs.
     * It takes in `params` as an argument, which contains the necessary data to fetch the email logs.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at
     */
    getReferAndEarnDetails: (params) => {
        return dispatch => {
            dispatch(request(GET_REFER_AND_EARN_DETAILS_REQUEST, params));
            return ReferAndEarnServices.getReferAndEarnDetails(params)
                .then(
                    response => { return dispatch(success(GET_REFER_AND_EARN_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_REFER_AND_EARN_DETAILS_FAILURE, params, error)); }
                );
        };

    },

};
