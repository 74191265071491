import React from 'react';
import { Paper, styled } from '@mui/material';

const DataTableStyled = styled(({ ...props }) => <Paper {...props} />)`
	/* border: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`}; */
	/* border-radius: ${(props) => props.theme.borders.borderRadius.md}; */
	border-radius: unset;
	padding: ${(props) => `0 ${props.theme.spacing(3)}`} !important;
	.rdt_TableHead {
		z-index: unset;
		border-top: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
	}
	.rdt_TableHeader {
		border-top-left-radius: ${(props) => props.theme.borders.borderRadius.md};
		border-top-right-radius: ${(props) => props.theme.borders.borderRadius.md};
		padding: ${(props) => `${props.theme.spacing(3)} 0`} !important;
		.MuiButton-root {
			min-width: 40px !important;
		}
	}
	header {
		min-height: 0 !important;
		padding: 0 !important;
	}

	.rdt_TableRow:last-child {
		border-bottom: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
	}

	.rdt_TableHeadRow {
		/* border-bottom: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`}; */
		min-height: ${(props) => `${props.theme.functions.pxToRem(50)}`};
		text-transform: uppercase;
		.rdt_TableCol:not(:first-of-type) {
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
		.rdt_TableCol:first-of-type {
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
		.rdt_TableCol:last-child {
			border-right: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
	}

	.rdt_Table {
		.rdt_TableCell:not(:first-of-type) {
			font-size: 12px !important;
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
		.rdt_TableCell:first-of-type {
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
		.rdt_TableCell:last-child {
			border-right: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
	}

	.rdt_TableRow {
		min-height: ${(props) => `${props.theme.spacing(5)}`};
		.action-row {
			width: 100%;
			display: none;
		}
		&:hover {
			border-bottom: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
			.action-chip {
				opacity: 0.1;
				text-transform: capitalize;
			}
			.action-row {
				display: flex;
				position: absolute;
				justify-content: center;
			}
		}
	}

	.rdt_Pagination {
		border-top: none;
		border-bottom-left-radius: ${(props) => props.theme.borders.borderRadius.md};
		border-bottom-right-radius: ${(props) => props.theme.borders.borderRadius.md};
		padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(5)}`} !important;
	}
`;

export { DataTableStyled };
