import {
	SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
	SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
	SEND_PWD_RST_LINK_REQUEST, SEND_PWD_RST_LINK_SUCCESS, SEND_PWD_RST_LINK_FAILURE,
	RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE
} from "../actions";

/**
 * This is defining the initial state of the Redux store for the application.
 * It contains several properties with their initial values:
 * */
const initialState = {
	sign_up_loading: false, sign_up: {}, sign_up_errors: {},
	sign_in_loading: false, sign_in: {}, sign_in_errors: {},
	send_pwd_rst_link_loading: false, send_pwd_rst_link_request: {}, send_pwd_rst_link: {}, send_pwd_rst_link_errors: {},
	reset_password_loading: false, reset_password_errors: {}
};


export function AuthReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::sign up redux */
		case SIGN_UP_REQUEST: {
			return {
				sign_up_loading: true,
			};
		}
		case SIGN_UP_SUCCESS: {
			return {
				sign_up_loading: false,
				sign_up: action.data,
				sign_up_errors: {}
			};
		}
		case SIGN_UP_FAILURE: {
			return {
				sign_up_loading: false,
				sign_up_errors: action.errors
			};
		}
		/** end::sign up redux */

		/** begin::Sign in redux */
		case SIGN_IN_REQUEST: {
			return {
				sign_in_loading: true,
				sign_in_user: {},
				sign_in_errors: {}
			};
		}
		case SIGN_IN_SUCCESS: {
			return {
				sign_in_loading: false,
				sign_in_user: action.data,
				sign_in_errors: {}
			};
		}
		case SIGN_IN_FAILURE: {
			return {
				sign_in_loading: false,
				sign_in_user: {},
				sign_in_errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::Send password reset link in redux */
		case SEND_PWD_RST_LINK_REQUEST: {
			return {
				send_pwd_rst_link_loading: true,
				send_pwd_rst_link_request: action.request,
				send_pwd_rst_link: {}
			};
		}
		case SEND_PWD_RST_LINK_SUCCESS: {
			return {
				send_pwd_rst_link_loading: false,
				send_pwd_rst_link: action.data,
			};
		}
		case SEND_PWD_RST_LINK_FAILURE: {
			return {
				send_pwd_rst_link_loading: false,
				send_pwd_rst_link: {},
				send_pwd_rst_link_errors: action.errors
			};
		}
		/** end::Send password reset link in redux */

		/** begin::Reset password redux */
		case RESET_PASSWORD_REQUEST: {
			return {
				reset_password_loading: true,
			};
		}
		case RESET_PASSWORD_SUCCESS: {
			return {
				reset_password_loading: false,
			};
		}
		case RESET_PASSWORD_FAILURE: {
			return {
				reset_password_loading: false,
				reset_password_errors: action.errors
			};
		}
		/** end::Reset password redux */

		default:
			return state;
	}
}
