import React from 'react';
import { Button } from '@mui/material';

function CancelButton({ text = 'Cancel', className, tabIndex, loading = false, disabled = false, onClick, props }) {
    return (
        <Button className={className} type="button" variant="contained" color="light" disabled={loading || disabled} tabIndex={tabIndex} onClick={() => onClick()} {...props} > {text} </Button>
    );
}

export { CancelButton };
