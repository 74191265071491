import colors from "../../../base/colors";
import typography from "../../../base/typography";
import pxToRem from "../../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    color: colors.common.white,
  },

  small: {},

  medium: {},

  large: {
    padding: `${pxToRem(12)} ${pxToRem(21)}`,
    fontSize: typography.size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${typography.size.sm} !important`,
    },
  },

  primary: {},

  secondary: {},

};
