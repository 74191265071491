import card from "../../../_root/components/card/card";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...card,
  styleOverrides: {
    ...card.styleOverrides,
    root: {
      ...card.styleOverrides.root,
      backgroundColor: colors.background.card,
    },
  },
};
