import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleInputChange, validateForm } from '../../../_helpers';
import { PreAuth } from '../../../_layouts/pre-auth';
import { postAuthRoutes, preAuthRoutes } from '../../../routes';
import { SubmitButton, FormInput, FormPassword } from '../../../_components';
import { Grid, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { AuthAction } from '../../../redux/actions';
import { SignInStyled, validate } from '.';

/* Initializing the input with the default values. */
const inputs = { email: '', password: '' };

function SignIn() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    /* Destructuring the state from redux store. */
    const { sign_in_loading: signingInProgress, sign_in_errors: signInErrors } = useSelector((state) => state.AuthReducer);
    const signIn = (params) => dispatch(AuthAction.signIn(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...signInErrors });
    }, [signInErrors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchSignInAction();
    }

    /**
     * It checks if the user is signed in, and if so, redirects to the home page
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchSignInAction() {
        /* Dispatching the signIn action and waiting for the response. */
        const isSignIn = await signIn(data);
        if (isSignIn.status === 1) {
            /** Checking if the user is redirected from a page, and if so, redirects to that page, else redirects to the home page. */
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : postAuthRoutes('mentors').path);
            navigate(`${redirectPath}`);
        }
    }

    return (
        <PreAuth>
            <SignInStyled>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            <Typography variant="h3">{`Welcome`}</Typography>
                            <Typography variant="body2" className='caption'>Please <span className='route-name'>{preAuthRoutes('sign_in').name}</span> to admin dashboard</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <FormInput
                                tabIndex={1}
                                label='Email'
                                name='email'
                                value={data.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} display="flex" alignItems="center" justifyContent="right"  >
                            <RouteLink to={preAuthRoutes('forgot_password').path} label={preAuthRoutes('forgot_password').name} tabIndex={4} className='f-password' />
                        </Grid> */}
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between" >
                            <SubmitButton fullWidth label={`${preAuthRoutes('sign_in').name}`} loading={signingInProgress} tabIndex={3} endIcon={<ArrowForward />} />
                        </Grid>
                        {/* <Grid item xs={12}  >
                            <RouteLink to={preAuthRoutes('sign_up').path} label={<Fragment>{preAuthRoutes('sign_up').name}  <ArrowRightAlt /></Fragment>} tabIndex={5} />
                        </Grid> */}
                    </Grid>
                </form>
            </SignInStyled>
        </PreAuth>
    );
}

export { SignIn };