module.exports = {

    /**
     * function to validate the sign in form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            /* Checking if the email is valid or not. */
            case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid.';
                if (value === '')
                    errors.email = 'Enter an email address.';
                break;
            }
            /* Checking if the password is empty or not. */
            case 'password': {
                errors.password = '';
                if (value === '') {
                    errors.password = 'Enter your password';
                }
                // else if (value.length < 8) {
                //     errors.password = `Password must be at least ${8} characters long.`;
                // }
                // else {
                //     // Check for password strength (e.g., at least one uppercase letter, lowercase letter, digit, and special character)
                //     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                //     if (!passwordRegex.test(value))
                //         errors.password = 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.';
                // }
                break;
            }
            default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
};