
import root from "./root";
import contained from "./contained";
import outlined from "./outlined";
import text from "./text";
import button from "../../../../_root/components/buttons/button/button";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...button,
  styleOverrides: {

    root: { ...root },

    contained: { ...contained.base },
    containedSizeSmall: { ...contained.small },
    containedSizeMedium: { ...contained.medium },
    containedSizeLarge: { ...contained.large },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },

    outlined: { ...outlined.base },
    outlinedSizeSmall: { ...outlined.small },
    outlinedSizeMedium: { ...outlined.medium },
    outlinedSizeLarge: { ...outlined.large },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },

    text: { ...text.base },
    textSizeSmall: { ...text.small },
    textSizeMedium: { ...text.medium },
    textSizeLarge: { ...text.large },
    textPrimary: { ...text.primary },
    textSecondary: { ...text.secondary },
  }
};
