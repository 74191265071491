import borders from "../../base/borders";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    inputRoot: {
      padding: `${pxToRem(0)} !important`,
    },
    popper: {
      marginTop: `${pxToRem(8)} !important`,
      marginBottom: `${pxToRem(8)} !important`,
      fontSize: typography.size.md,
      textAlign: "left",
      borderRadius: borders.borderRadius.md,
    },
    input: {
      padding: `${pxToRem(12)} !important`,
    },
    paper: {
      padding: `${pxToRem(0)} !important`,
      display: 'block'
    },
    listbox: {
      padding: `${pxToRem(0)} !important`,
    },
    option: {
      borderRadius: borders.borderRadius.xs,
      fontSize: typography.size.md,
      transition: "background-color 300ms ease, color 300ms ease",
    },
    noOptions: {
      fontSize: typography.size.md,
    },
    loading: {
      fontSize: typography.size.md,
    },
    tag: {
      display: "flex",
      alignItems: "center",
      height: "auto",
      padding: pxToRem(4),
      "& .MuiChip-label": {
        lineHeight: typography.lineHeight.sm,
        padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
      },
      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        marginRight: 0,
      },
    },
  },
  variants: []
};
