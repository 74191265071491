import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { CardMedia, Grid } from '@mui/material';
import { validateForm } from '../../../../_helpers';
import { ProfileAction } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { postAuthRoutes, preAuthRoutes } from "../../../../routes";
import { SubmitButton, FormPassword, CardContent, CardHeader, SweetAlert, Animate } from '../../../../_components';
import { ChangePasswordStyled, validateChangePassword } from '.';
import changePasswordImage from './images/change-password.png';

/* Initializing the input with the default values. */
const inputs = { current_password: '', new_password: '', confirm_password: '' };

function ChangePassword() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* Destructuring the state from redux store. */
    const changePassword = (params) => dispatch(ProfileAction.changePassword(params));
    const { change_password_loading, change_password_errors } = useSelector((state) => state.ProfileReducer);


    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        setErrors({ ...change_password_errors });
    }, [change_password_errors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = e;
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validateChangePassword(name, value, data)) });
        data[e.target.name] = e.target.value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateChangePassword(name, value, data);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdatePassword(data);
    }

    /**
     * The function dispatches an action to change the user's password and handles the response accordingly.
     * @param {Object} data
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    const dispatchUpdatePassword = (data) => {
        let dispatchAction = changePassword(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                let timerInterval;
                SweetAlert.fire({
                    title: 'Password Updated!',
                    html: "Your account password has been updated.You will be redirected to the login screen soon!<br/><br/>I will close in <b></b> milliseconds.Please login again.",
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        SweetAlert.showLoading();
                        const b = SweetAlert.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft(); }, 200);
                    },
                    willClose: () => {
                        setTimeout(() => {
                            let redirectPath = preAuthRoutes('sign_in').path;
                            navigate(redirectPath);
                        }, 2000);
                        clearInterval(timerInterval);
                    }
                });
            } else {
                setErrors({ ...response.errors });
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }

        });
    };

    return (
        <ChangePasswordStyled >
            <CardHeader title={postAuthRoutes('change_password').name} />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={6} display={`flex`} alignItems={`center`}>
                        <Animate animate='right-left'>
                            <form onSubmit={handleSubmit} noValidate >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='Current password'
                                            name='current_password'
                                            value={data.current_password}
                                            error={action.isSubmitted && errors.current_password ? errors.current_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='New password'
                                            name='new_password'
                                            value={data.new_password}
                                            error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormPassword
                                            tabIndex={1}
                                            label='Confirm password'
                                            name='confirm_password'
                                            value={data.confirm_password}
                                            error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <SubmitButton label={'Change Password'} fullWidth loading={change_password_loading} />
                                    </Grid>
                                </Grid>
                            </form>
                        </Animate>
                    </Grid>
                    <Grid item xs={6} className='right-grid'>
                        <Animate>
                            <CardMedia component="img" image={changePasswordImage} />
                        </Animate>
                    </Grid>
                </Grid>
            </CardContent>
        </ChangePasswordStyled>

    );
}


export { ChangePassword };
