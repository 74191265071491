
import contained from "../../../../_root/components/buttons/button/contained";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...contained,
  base: {
    ...contained.base,
    "&.MuiButton-containedLight": {
      color: colors.text.dark
    }
  }
};
