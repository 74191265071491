import { Grid, Typography, Skeleton, Stack, Box, Chip, Avatar, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DataTable, PageHeader } from '../../../../_components';
import { ReferAndEarnAction } from '../../../../redux/actions';
import { CalendarMonth, Call, Email } from '@mui/icons-material';
import { ReferAndEarnDetailsStyled } from '.';

import { deepPurple } from '@mui/material/colors';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';


function ReferAndEarnDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { id } = useParams();

    /* Destructuring the state from redux store. */
    const { refer_and_earn_details, refer_and_earn_details_loading } = useSelector((state) => state.ReferAndEarnReducer);
    const getReferAndEarnDetails = (params) => dispatch(ReferAndEarnAction.getReferAndEarnDetails(params));

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (id) {
            getReferAndEarnDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'first_name', sortable: true, center: false, width: '20%' },
        { name: "Phone", selector: (row, i) => (row.phone_number), sortField: 'phone_number', sortable: true, center: false, width: '25%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '25%' },
        { name: "Joined At", selector: (row, i) => (row.joined_at), sortField: 'joined_at', sortable: true, center: true, width: '15%' },
        { name: "Status", selector: (row, i) => (row.status_text), sortField: 'status', sortable: true, center: true, width: '10%' },
    ];

    const handlePage = () => {

    };



    if (refer_and_earn_details_loading)
        return '';
    else
        return (
            <ReferAndEarnDetailsStyled>

                <Grid container spacing={2} display={`flex`} alignItems={`stretch`} justifyContent={`center`}>
                    <Grid item xs={12}>
                        <DataTable title={`Mentors`} loading={false} columns={columns} data={refer_and_earn_details.references} setPage={page => handlePage(page)} total="3" per_page="10" />
                    </Grid>
                </Grid>

            </ReferAndEarnDetailsStyled>
        );
}

export { ReferAndEarnDetails };