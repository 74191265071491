import React from 'react'
import { Box, styled } from '@mui/material'
import backgroundImage from './images/background.jpg'

const PreAuthStyled = styled(({ ...props }) => <Box {...props} />)`
	background-image: url(${backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.95); /* Adjust the alpha (last) value to control the darkness of the overlay */
	}
	& .grid-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		& .grid-wrapper-inner {
			min-width: ${(props) => `${props.theme.functions.pxToRem(430)}`};
			max-width: ${(props) => `${props.theme.functions.pxToRem(800)}`};
			min-height: ${(props) => `${props.theme.functions.pxToRem(400)}`};
			background: transparent;
			border: none;
			box-shadow: none;
			& .MuiCardContent-root {
				padding: 0;
				& .MuiGrid-container {
					margin: 0;
				}
				& .left-content {
					display: flex;
					align-items: center;
					justify-content: center;
					border-right: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
				}
				& .right-content {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: ${(props) => props.theme.spacing(8)};
					padding-left: ${(props) => props.theme.spacing(5.5)};
					& .MuiGrid-item {
						padding-left: ${(props) => props.theme.spacing(2)};
						padding-right: ${(props) => props.theme.spacing(2)};
					}
					& .caption {
						& .route-name {
							text-transform: lowercase;
						}
					}
				}
			}
		}
	}
	& .f-password {
		font-size: ${(props) => props.theme.typography.size.xs};
	}
`

export { PreAuthStyled }
