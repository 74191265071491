// eslint-disable-next-line import/no-anonymous-default-export
export default {
	defaultProps: {},
	styleOverrides: {
		root: {
			position: 'relative',
			overflow: 'visible',
		}
	},
	variants: []
};
