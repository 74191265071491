import React from 'react'
import { Card, styled } from '@mui/material'

const FormStyled = styled(({ ...props }) => <Card {...props} />)`
	box-shadow: none;
	background: none;
	border: none;
`

export { FormStyled }
