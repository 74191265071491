import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: typography.size.md,
      lineHeight: typography.lineHeight.xs,
      "&.MuiInputLabel-shrink": {
        fontSize: typography.size.sm,
        lineHeight: typography.lineHeight.xs,
        top: pxToRem(4),
        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: typography.size.sm,
        },
      },
      "&.Mui-error": {
        color: `initial`
      }
    },
  },
  variants: []
};
