import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAuthRoutes } from "../../../../routes";
import { ButtonGroup, Chip, Tooltip, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility as View } from "@mui/icons-material";
import queryString from 'query-string';
import { AbilityCan } from "../../../../_helpers/permission/AbilityCan";
import { FormSearchInput, DataTable } from "../../../../_components";
import { ReferAndEarnAction } from "../../../../redux/actions";

function ListReferAndEarn() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search: searchParam } = useLocation();
    const searchParams = new URLSearchParams(searchParam);

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);
    const { refer_and_earn_total, refer_and_earn_per_page, refer_and_earn_loading, refer_and_earn } = useSelector((state) => state.ReferAndEarnReducer);
    const getReferAndEarnList = (params) => dispatch(ReferAndEarnAction.getReferAndEarnList(params));

    /* Initializing the state with the default values. */
    const [showStatusUpdateDialog, setShowStatusUpdateDialog] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', item: '', status: '', to_status_label: '', to_status_btn_color: '' });
    const [payload, setPayload] = useState({
        page: searchParams.get('page') ?? 1,
        filter: {
            status: searchParams.get('status') ?? '',
        },
        search: searchParams.get('q') ?? '',
        sort: { [searchParams.get('sort_field') ?? 'name']: searchParams.get('sort_order') ?? 'asc', }
    });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.page = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        queryParam.sort_field = Object.keys(payload.sort)[0];
        queryParam.sort_order = Object.values(payload.sort)[0];

        navigate(`${postAuthRoutes('refer_and_earn').path}?${queryString.stringify(queryParam)}`);
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.sort]);

    /* `const columns` is an array of objects that defines the columns of a data table. Each object
    represents a column and contains properties such as `name`, `selector`, `sortField`, `sortable`,
    `center`, `width`, and `cell`. */
    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((refer_and_earn_per_page * (payload.page - 1)) + (i + 1)) },
        { name: "Third Party", selector: (row, i) => (`${row.third_party_type_text}`), sortField: 'third_party_type', sortable: true, center: false, width: '10%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '36%' },
        { name: "Organization", selector: (row, i) => (row.organization), sortField: 'organization', sortable: true, center: true, width: '17%' },
        { name: "Mentors", selector: (row, i) => (row.mentors_count.total), sortField: 'mentors', sortable: true, center: true, width: '8%' },
        { name: "Mentees", selector: (row, i) => (row.mentees_count.total), sortField: 'mentees', sortable: true, center: true, width: '8%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '8%', cell: row => (<StatusRow row={row} />) },
        { name: "Action", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '8%', cell: row => (<ActionsRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {String} row.status - status values on the row
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const StatusRow = ({ row }) => {

        /* This code is defining a `StatusRow` component that renders a `Chip` component with a label of
        the `status` property of the `row` object passed as a prop. If the `status` property is equal
        to the string "active", the `updateButtonText` variable is set to "Inactivate" and the
        `status` variable is updated to render a `Chip` component with a green color. Otherwise,
        `updateButtonText` is set to "Activate" and the `status` variable renders a `Chip` component
        with a red color. This component is used to display the status of a third_party in a data table. */
        let status = <Chip label={row.status_text} color={row.status_color} />;
        return <Fragment>{status} </Fragment>;
    };

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {String} row.status - status values on the row
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const ActionsRow = ({ row }) => {

        /**
         * The function handles a click event and navigates to a route for updating a role with
         * the provided row data.
         * @param {Object} row - The parameter "row" is likely an object representing a row of data in a table or
         * list. It is being passed as an argument to the function "handleViewClick". The function
         * then uses this row object to navigate to a specific route and pass the row object as state data.
         * @return {null}
         * @author Akshay N
         * @created_at 21/05/2023
         */
        function handleViewClick(row) {
            navigate((postAuthRoutes('view_refer_and_earn').path).replace(':id', row._id));
        }




        /* The above code is defining a JSX element called `statusHover` which contains a `ButtonGroup`
        component with three `IconButton` components inside. Each `IconButton` is wrapped in a
        `Tooltip` component and an `AbilityCan` component which conditionally renders the
        `IconButton` based on the third_party's role and permissions. The `onClick` event of each
        `IconButton` is set to a corresponding function that handles the click event. */
        let statusChange = (
            (row.status === 'active' || row.status === 'inactive') ? (
                <ButtonGroup variant="text" size="small">
                    <AbilityCan I='admin_view_third_party_details' passThrough={(user.role === 'developer') ? true : false}>
                        <Tooltip title="View ReferAndEarn Details">
                            <IconButton onClick={() => handleViewClick(row)} size="small"><View /></IconButton>
                        </Tooltip>
                    </AbilityCan>
                </ButtonGroup>
            ) : '-'
        );
        return <Fragment>{statusChange} </Fragment>;
    };

    /**
     * The function `getData` calls the `getReferAndEarnList` function with the `payload` parameter after
     * awaiting its execution.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const getData = async () => {
        await getReferAndEarnList(payload);
    };

    /**
     * The function handles sorting based on a specified column and sort direction.
     * @param column - The "column" parameter is likely an object that represents a column in a table
     * or grid. It probably contains information such as the column's display name, data type, and
     * sorting options. The "sortField" property of the column object is likely the name of the field
     * in the data that should
     * @param sortDirection - sortDirection is a string parameter that represents the direction of
     * sorting. It can have two possible values: "asc" for ascending order and "desc" for descending
     * order.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setPayload({ ...payload, sort: { [sortField]: sortDirection } });
    };


    /**
     * The function updates the page value in the payload object.
     * @param page - The `page` parameter is a variable that represents the current page number. It is
     * used in the `handlePage` function to update the `page` property of the `payload` object using
     * the `setPayload` function. The `...payload` syntax is used to spread the existing properties of
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    };

    /**
     * The function applies a filter to the payload data and sets the page to 1.
     * @param filterData - filterData is a parameter that represents the data used to filter the
     * payload. It could be an object containing various filter options such as search keywords, date
     * range, category, etc. The function applies the filterData to the payload by updating the filter
     * property with the new data and resetting the page property to
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    // const applyFilter = (filterData) => {
    //     setPayload({ ...payload, page: 1, filter: filterData });
    // };

    /**
     * The function updates the payload object with a new search text and resets the page number to 1.
     * @param text - The `text` parameter is a string that represents the search query entered by the
     * third_party. It is used as an argument for the `applySearch` function.
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    /**
     * This function opens a modal to update the status of an item.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const statusUpdateDialog = ({ id, status, item }) => {

        let toStatusLabel = 'activate';
        let toStatusBtnColor = 'success';
        if (status === 'account_created' || status === 'active') {
            toStatusLabel = 'deactivate';
            toStatusBtnColor = 'error';
        }

        setStatusData({ id: id, status: status, item: item, to_status_label: toStatusLabel, to_status_btn_color: toStatusBtnColor });
        setShowStatusUpdateDialog(!showStatusUpdateDialog);
    };

    return (
        <Fragment>
            <DataTable
                title={`Refer and Earn`}
                loading={refer_and_earn_loading}
                columns={columns}
                data={refer_and_earn}
                total={refer_and_earn_total}
                per_page={refer_and_earn_per_page}
                setPage={page => handlePage(page)}
                handleSort={handleSort}
                actions={
                    <Stack spacing={1} direction="row">
                        <Stack spacing={1} direction="row">
                            <AbilityCan I='admin_refer_and_earn_search' passThrough={(user.role === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                        </Stack>
                    </Stack>
                }
                paginationDefaultPage={payload.page}
            />
        </Fragment>

    );
}
export { ListReferAndEarn };