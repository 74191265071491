import {
    GET_MENTEE_LIST_REQUEST, GET_MENTEE_LIST_SUCCESS, GET_MENTEE_LIST_FAILURE,
    CHANGE_MENTEE_STATUS_REQUEST, CHANGE_MENTEE_STATUS_SUCCESS, CHANGE_MENTEE_STATUS_FAILURE,
    GET_MENTEE_DETAILS_REQUEST, GET_MENTEE_DETAILS_SUCCESS, GET_MENTEE_DETAILS_FAILURE,
    RESET_MENTEE_PROFILE_REQUEST, RESET_MENTEE_PROFILE_SUCCESS, RESET_MENTEE_PROFILE_FAILURE, REMOVE_MENTEE_REQUEST, REMOVE_MENTEE_SUCCESS, REMOVE_MENTEE_FAILURE
} from '../actions';

const initialState = {
    mentees_loading: false, mentees: [], mentees_errors: {}, mentees_total: 0, mentees_total_pages: 0, mentees_per_page: 25, mentees_page: 1,
    change_mentee_status_loading: false, change_mentee_status: {}, change_mentee_status_errors: {},
    mentee_loading: true, mentee: {}, mentee_errors: {}
};

export function MenteeReducer(state = initialState, action) {
    switch (action.type) {
        //  begin::Get email logs list redux
        case GET_MENTEE_LIST_REQUEST: {
            return {
                ...state,
                mentees_loading: true,
                mentees_errors: false,
            };
        }
        case GET_MENTEE_LIST_SUCCESS: {
            let newArray = []; //making a new array
            action.data.mentees.forEach((payload) => {
                const menteeIndex = newArray.findIndex(mentee => mentee._id === payload._id);
                if (menteeIndex !== -1) {
                    newArray[menteeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                mentees_loading: false,
                mentees: newArray,
                mentees_errors: {},
                mentees_page: action.data.page,
                mentees_per_page: action.data.per_page,
                mentees_total: action.data.total,
                mentees_total_pages: action.data.total_pages
            };
        }
        case GET_MENTEE_LIST_FAILURE: {
            return {
                ...state,
                mentees_loading: false,
                mentees_errors: action.errors,
            };
        }
        // begin::Get email logs list redux


        //  begin::get user redux
        case CHANGE_MENTEE_STATUS_REQUEST: {
            return {
                ...state,
                change_mentee_status_loading: true,
                change_mentee_status: {}
            };
        }
        case CHANGE_MENTEE_STATUS_SUCCESS: {

            let menteesArray = [...state.mentees];
            const menteeIndex = menteesArray.findIndex(mentee => mentee._id === action.data._id);
            if (menteeIndex !== -1) {
                menteesArray[menteeIndex] = action.data;
            } else {
                menteesArray = menteesArray.concat(action.data);
            }

            return {
                ...state,
                change_mentee_status_loading: false,
                change_mentee_status: action.data,
                change_mentee_status_errors: {},
                mentees: menteesArray
            };
        }
        case CHANGE_MENTEE_STATUS_FAILURE: {
            return {
                ...state,
                change_mentee_status_loading: false,
                change_mentee_status_errors: action.errors,
            };
        }
        // begin::get user redux

        //  begin::get user redux
        case GET_MENTEE_DETAILS_REQUEST: {
            return {
                ...state,
                mentee_loading: true,
                mentee: {}
            };
        }
        case GET_MENTEE_DETAILS_SUCCESS: {

            let menteesArray = [...state.mentees];
            const menteeIndex = menteesArray.findIndex(mentee => mentee._id === action.data._id);
            if (menteeIndex !== -1) {
                menteesArray[menteeIndex] = action.data;
            } else {
                menteesArray = menteesArray.concat(action.data);
            }

            return {
                ...state,
                mentee_loading: false,
                mentee: action.data,
                mentee_errors: {},
                mentees: menteesArray
            };
        }
        case GET_MENTEE_DETAILS_FAILURE: {
            return {
                ...state,
                mentee_loading: false,
                mentee_errors: action.errors,
            };
        }
        // begin::get user redux

        //  begin::reset mentee profile
        case RESET_MENTEE_PROFILE_REQUEST: {
            return {
                ...state,
                mentee_loading: true,
                mentee: {}
            };
        }
        case RESET_MENTEE_PROFILE_SUCCESS: {

            let menteesArray = [...state.mentees];
            const menteeIndex = menteesArray.findIndex(mentee => mentee._id === action.data._id);
            if (menteeIndex !== -1) {
                menteesArray[menteeIndex] = action.data;
            } else {
                menteesArray = menteesArray.concat(action.data);
            }

            return {
                ...state,
                mentee_loading: false,
                mentee: action.data,
                mentee_errors: {},
                mentees: menteesArray
            };
        }
        case RESET_MENTEE_PROFILE_FAILURE: {
            return {
                ...state,
                mentee_loading: false,
                mentee_errors: action.errors,
            };
        }
        // begin::reset mentee profile

        //  begin::remove mentee profile
        case REMOVE_MENTEE_REQUEST: {
            return {
                ...state,
                mentees_loading: true,
            };
        }
        case REMOVE_MENTEE_SUCCESS: {

            let menteesArray = [...state.mentees];

            const menteeIndex = menteesArray.findIndex(mentee => mentee._id === action.data._id);
            if (menteeIndex !== -1) {
                menteesArray.splice(menteeIndex, 1);
            }

            return {
                ...state,
                mentees_loading: false,
                mentees_errors: {},
                mentees: menteesArray
            };
        }
        case REMOVE_MENTEE_FAILURE: {
            return {
                ...state,
                mentees_loading: false,
                mentees_errors: action.errors,
            };
        }
        // begin::reset mentee profile

        default:
            return state;
    }

}
