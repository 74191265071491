import { apiServices } from "../../_helpers";

export const MentorServices = {

    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getMentorList: (params) => {
        return apiServices.get(`/mentors/list`, { params })
            .then((response) => { return response; });
    },
    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    changeMentorStatus: (params) => {
        return apiServices.put(`/mentor/status/update`, params)
            .then((response) => { return response; });
    },
    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getMentorDetails: (id) => {
        return apiServices.get(`/mentor/${id}`)
            .then((response) => { return response; });
    },
    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    resetMentorProfile: (id) => {
        return apiServices.get(`/mentor/reset/${id}`)
            .then((response) => { return response; });
    },
    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    removeMentor: (id) => {
        return apiServices.delete(`/mentor/remove/${id}`)
            .then((response) => { return response; });
    },
};
