
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {},

  small: {},

  medium: {},

  large: {},

  primary: {},

  secondary: {},

};
