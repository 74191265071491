
import { createTheme } from "@mui/material/styles";

import colors from "./base/colors";
import breakpoints from "./base/breakpoints";
import typography from "./base/typography";
import boxshadows from "./base/boxshadows";
import borders from "./base/borders";
import globals from "./base/globals";
import container from "./base/container";

import boxShadow from "../_root/functions/boxShadow";
import hexToRgb from "../_root/functions/hexToRgb";
import linearGradient from "../_root/functions/linearGradient";
import pxToRem from "../_root/functions/pxToRem";
import rgba from "../_root/functions/rgba";

import accordion from "./components/accordion/accordion";
import accordionActions from "./components/accordion/accordionActions";
import accordionDetails from "./components/accordion/accordionDetails";
import accordionSummary from "./components/accordion/accordionSummary";
import alert from "./components/alert/alert";
import alertTitle from "./components/alert/alertTitle";
import appBar from "./components/appbar/appBar";
import autocomplete from "./components/form/autocomplete";
import avatar from "./components/avatar/avatar";
import avatarGroup from "./components/avatar/avatarGroup";
import backdrop from "./components/backdrop/backdrop";
import badge from "./components/badge/badge";
import bottomNavigation from "./components/bottom-navigation/bottomNavigation";
import bottomNavigationAction from "./components/bottom-navigation/bottomNavigationAction";
import breadcrumbs from "./components/breadcrumbs/breadcrumbs";
import button from "./components/buttons/button/button";
import buttonBase from "./components/buttons/buttonBase";
import buttonGroupBase from "./components/buttons/buttonGroupBase";
import card from "./components/card/card";
import cardActionArea from "./components/card/cardActionArea";
import cardActions from "./components/card/cardActions";
import cardContent from "./components/card/cardContent";
import cardHeader from "./components/card/cardHeader";
import cardMedia from "./components/card/cardMedia";
import checkbox from "./components/form/checkbox";
import chip from "./components/chip/chip";
import circularProgress from "./components/circular-progress/circularProgress";
import collapse from "./components/collapse/collapse";
import dialog from "./components/dialog/dialog";
import dialogActions from "./components/dialog/dialogActions";
import dialogContent from "./components/dialog/dialogContent";
import dialogContentText from "./components/dialog/dialogContentText";
import dialogTitle from "./components/dialog/dialogTitle";
import divider from "./components/divider/divider";
import drawer from "./components/drawer/drawer";
import fab from "./components/buttons/button-fab/fab";
import filledInput from "./components/form/filledInput";
import formControl from "./components/form/formControl";
import formControlLabel from "./components/form/formControlLabel";
import formGroup from "./components/form/formGroup";
import formHelperText from "./components/form/formHelperText";
import formLabel from "./components/form/formLabel";
import grid from "./components/grid/grid";
import grid2 from "./components/grid/grid2";
import imageList from "./components/image-list/imageList";
import imageListItem from "./components/image-list/imageListItem";
import imageListItemBar from "./components/image-list/imageListItemBar";
import icon from "./components/icon/icon";
import iconButton from "./components/buttons/icon-button/iconButton";
import input from "./components/form/input";
import inputAdornment from "./components/form/inputAdornment";
import inputBase from "./components/form/inputBase";
import inputLabel from "./components/form/inputLabel";
import linearProgress from "./components/linear-progress/linearProgress";
import link from "./components/link/link";
import list from "./components/list/list";
import listItem from "./components/list/listItem";
import listItemButton from "./components/list/listItemButton";
import listItemAvatar from "./components/list/listItemAvatar";
import listItemIcon from "./components/list/listItemIcon";
import listItemSecondaryAction from "./components/list/listItemSecondaryAction";
import listItemText from "./components/list/listItemText";
import listSubheader from "./components/list/listSubheader";
import menu from "./components/menu/menu";
import menuItem from "./components/menu/menuItem";
import menuList from "./components/menu/menuList";
import mobileStepper from "./components/mobile-stepper/mobileStepper";
import modal from "./components/modal/modal";
import nativeSelect from "./components/native-select/nativeSelect";
import inputOutlined from "./components/form/inputOutlined";
import pagination from "./components/pagination/pagination";
import paginationItem from "./components/pagination/paginationItem";
import paper from "./components/paper/paper";
import popper from "./components/popper/popper";
import popover from "./components/popover/popover";
import radio from "./components/form/radio";
import rating from "./components/form/rating";
import scopedCssBaseline from "./components/scoped-css-baseline/scopedCssBaseline";
import select from "./components/form/select";
import skelton from "./components/skelton/skelton";
import slider from "./components/slider/slider";
import snackbar from "./components/snackbar/snackbar";
import snackbarContent from "./components/snackbar/snackbarContent";
import speedDial from "./components/speed-dial/speedDial";
import speedDialAction from "./components/speed-dial/speedDialAction";
import speedDialIcon from "./components/speed-dial/speedDialIcon";
import stack from "./components/stack/stack";
import step from "./components/stepper/step";
import stepButton from "./components/stepper/stepButton";
import stepConnector from "./components/stepper/stepConnector";
import stepContent from "./components/stepper/stepContent";
import stepIcon from "./components/stepper/stepIcon";
import stepLabel from "./components/stepper/stepLabel";
import stepper from "./components/stepper/stepper";
import svgIcon from "./components/icon/svgIcon";
import swipeableDrawer from "./components/drawer/swipeableDrawer";
import formSwitch from "./components/form/switch";
import tab from "./components/tab/tab";
import table from "./components/table/table";
import tableBody from "./components/table/tableBody";
import tableCell from "./components/table/tableCell";
import tableContainer from "./components/table/tableContainer";
import tableFooter from "./components/table/tableFooter";
import tableHead from "./components/table/tableHead";
import tablePagination from "./components/table/tablePagination";
import tableRow from "./components/table/tableRow";
import tableSortLabel from "./components/table/tableSortLabel";
import tabs from "./components/tab/tabs";
import textField from "./components/form/textField";
import toggleButton from "./components/buttons/toggle-button/toggleButton";
import toggleButtonGroup from "./components/buttons/toggle-button/toggleButtonGroup";
import toolbar from "./components/toolbar/toolbar";
import tooltip from "./components/tooltip/tooltip";

export default createTheme({
    breakpoints: { ...breakpoints },
    components: {
        MuiAccordion: { ...accordion },
        MuiAccordionActions: { ...accordionActions },
        MuiAccordionDetails: { ...accordionDetails },
        MuiAccordionSummary: { ...accordionSummary },
        MuiAlert: { ...alert },
        MuiAlertTitle: { ...alertTitle },
        MuiAppBar: { ...appBar },
        MuiAutocomplete: { ...autocomplete },
        MuiAvatar: { ...avatar },
        MuiAvatarGroup: { ...avatarGroup },
        MuiBackdrop: { ...backdrop },
        MuiBadge: { ...badge },
        MuiBottomNavigation: { ...bottomNavigation },
        MuiBottomNavigationAction: { ...bottomNavigationAction },
        MuiBreadcrumbs: { ...breadcrumbs },
        MuiButton: { ...button },
        MuiButtonBase: { ...buttonBase },
        MuiButtonGroup: { ...buttonGroupBase },
        MuiCard: { ...card },
        MuiCardActionArea: { ...cardActionArea },
        MuiCardActions: { ...cardActions },
        MuiCardContent: { ...cardContent },
        MuiCardHeader: { ...cardHeader },
        MuiCardMedia: { ...cardMedia },
        MuiCheckbox: { ...checkbox },
        MuiChip: { ...chip },
        MuiCircularProgress: { ...circularProgress },
        MuiCollapse: { ...collapse },
        MuiCssBaseline: { styleOverrides: { ...globals, ...container, } },
        MuiDialog: { ...dialog },
        MuiDialogActions: { ...dialogActions },
        MuiDialogContent: { ...dialogContent },
        MuiDialogContentText: { ...dialogContentText },
        MuiDialogTitle: { ...dialogTitle },
        MuiDivider: { ...divider },
        MuiDrawer: { ...drawer },
        MuiFab: { ...fab },
        MuiFilledInput: { ...filledInput },
        MuiFormControl: { ...formControl },
        MuiFormControlLabel: { ...formControlLabel },
        MuiFormGroup: { ...formGroup },
        MuiFormHelperText: { ...formHelperText },
        MuiFormLabel: { ...formLabel },
        MuiGrid: { ...grid },
        MuiGrid2: { ...grid2 },
        MuiImageList: { ...imageList },
        MuiImageListItem: { ...imageListItem },
        MuiImageListItemBar: { ...imageListItemBar },
        MuiIcon: { ...icon },
        MuiIconButton: { ...iconButton },
        MuiInput: { ...input },
        MuiInputAdornment: { ...inputAdornment },
        MuiInputBase: { ...inputBase },
        MuiInputLabel: { ...inputLabel },
        MuiLinearProgress: { ...linearProgress },
        MuiLink: { ...link },
        MuiList: { ...list },
        MuiListItem: { ...listItem },
        MuiListItemButton: { ...listItemButton },
        MuiListItemAvatar: { ...listItemAvatar },
        MuiListItemIcon: { ...listItemIcon },
        MuiListItemSecondaryAction: { ...listItemSecondaryAction },
        MuiListItemText: { ...listItemText },
        MuiListSubheader: { ...listSubheader },
        MuiMenu: { ...menu },
        MuiMenuItem: { ...menuItem },
        MuiMenuList: { ...menuList },
        MuiMobileStepper: { ...mobileStepper },
        MuiModal: { ...modal },
        MuiNativeSelect: { ...nativeSelect },
        MuiOutlinedInput: { ...inputOutlined },
        MuiPagination: { ...pagination },
        MuiPaginationItem: { ...paginationItem },
        MuiPaper: { ...paper },
        MuiPopper: { ...popper },
        MuiPopover: { ...popover },
        MuiRadio: { ...radio },
        MuiRating: { ...rating },
        MuiScopedCssBaseline: { ...scopedCssBaseline },
        MuiSelect: { ...select },
        MuiSkeleton: { ...skelton },
        MuiSlider: { ...slider },
        MuiSnackbar: { ...snackbar },
        MuiSnackbarContent: { ...snackbarContent },
        MuiSpeedDial: { ...speedDial },
        MuiSpeedDialAction: { ...speedDialAction },
        MuiSpeedDialIcon: { ...speedDialIcon },
        MuiStack: { ...stack },
        MuiStep: { ...step },
        MuiStepButton: { ...stepButton },
        MuiStepConnector: { ...stepConnector },
        MuiStepContent: { ...stepContent },
        MuiStepIcon: { ...stepIcon },
        MuiStepLabel: { ...stepLabel },
        MuiStepper: { ...stepper },
        MuiSvgIcon: { ...svgIcon },
        MuiSwipeableDrawer: { ...swipeableDrawer },
        MuiSwitch: { ...formSwitch },
        MuiTab: { ...tab },
        MuiTable: { ...table },
        MuiTableBody: { ...tableBody },
        MuiTableCell: { ...tableCell },
        MuiTableContainer: { ...tableContainer },
        MuiTableFooter: { ...tableFooter },
        MuiTableHead: { ...tableHead },
        MuiTablePagination: { ...tablePagination },
        MuiTableRow: { ...tableRow },
        MuiTableSortLabel: { ...tableSortLabel },
        MuiTabs: { ...tabs },
        MuiTextField: { ...textField },
        MuiToggleButton: { ...toggleButton },
        MuiToggleButtonGroup: { ...toggleButtonGroup },
        MuiToolbar: { ...toolbar },
        MuiTooltip: { ...tooltip },
    },
    palette: { mode: 'dark', ...colors },
    typography: { ...typography },
    boxshadows: { ...boxshadows },
    borders: { ...borders },
    functions: { boxShadow, hexToRgb, linearGradient, pxToRem, rgba },
});
