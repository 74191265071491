import React from 'react';
import { Box, styled } from '@mui/material';

const ReferAndEarnDetailsStyled = styled(({ ...props }) => <Box {...props} />)`
	& .social-profiles {
		& .MuiCardMedia-root {
			margin: 0;
			margin-right: 8px;
			height: 24px;
		}
	}
	& .profile-media {
		& .MuiCardMedia-root {
			max-height: 360px;
			min-height: 360px;
			width: 100%;
			margin: 0;
			background-color: #000;
		}
	}
	& .mentorship-tags {
		& .MuiChip-root {
			border-radius: 0;
		}
	}
`;

export { ReferAndEarnDetailsStyled };
