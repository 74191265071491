import { Grid, Typography, Skeleton, CardMedia, Stack, Box, Chip, Avatar, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageHeader, Carousel, RouteLink } from '../../../../_components';
import { MenteeAction } from '../../../../redux/actions';
import defaultMenteeImage from './images/default-mentee.jpg';
import { AttachMoney, CalendarMonth, Call, Email } from '@mui/icons-material';
import { MenteeDetailsStyled } from '.';

import instagramIcon from './images/instagram-icon.png';
import tiktokIcon from './images/tiktok-icon.png';
import linledinIcon from './images/linkedin-icon.png';
import { deepPurple } from '@mui/material/colors';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';


function MenteeDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { id } = useParams();

    /* Destructuring the state from redux store. */
    const { mentee, mentee_loading } = useSelector((state) => state.MenteeReducer);
    const { user } = useSelector((state) => state.ProfileReducer);
    const getMenteeDetails = (params) => dispatch(MenteeAction.getMenteeDetails(params));
    const resetMenteeProfile = (params) => dispatch(MenteeAction.resetMenteeProfile(params));

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        if (id) {
            getMenteeDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleResetMenteeProfile = () => {
        resetMenteeProfile(id);
    };


    // const mentee = [
    //     {
    //         first_name: "Jibi",
    //         last_name: "Gopi",
    //         email: "jibi-gopi@gmail.com",
    //         phone: "+1(609)216-4001",
    //         joined_at: "14-17-2023",
    //         signup_code: "PAVE-001",
    //         status: "active",
    //         status_text: "Active"
    //     },
    //     {
    //         first_name: "Sanith",
    //         last_name: "Ammi",
    //         email: "sanithaish@gmail.com",
    //         phone: "+1(609)216-4002",
    //         joined_at: "12-19-2023",
    //         signup_code: "PAVE-001",
    //         status: "active",
    //         status_text: "Active"
    //     },
    //     {
    //         first_name: "Akshay",
    //         last_name: "Nadu",
    //         email: "akshaynadu@gmail.com",
    //         phone: "+1(609)216-4004",
    //         joined_at: "12-20-2023",
    //         signup_code: "PAVE-001",
    //         status: "active",
    //         status_text: "Active"
    //     }
    // ];



    // const columns = [
    //     { name: "No", center: true, width: '5%', cell: (row, i) => ((i + 1)) },
    //     { name: "First Name", selector: (row, i) => (row.first_name), sortField: 'first_name', sortable: true, center: false, width: '15%' },
    //     { name: "Last Name", selector: (row, i) => (row.last_name), sortField: 'last_name', sortable: true, center: false, width: '15%' },
    //     { name: "Phone", selector: (row, i) => (row.phone), sortField: 'phone', sortable: true, center: false, width: '25%' },
    //     { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '25%' },
    //     { name: "Joined At", selector: (row, i) => (row.joined_at), sortField: 'joined_at', sortable: true, center: true, width: '15%' },
    //     // { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '10%' },
    // ];


    // const handlePage = () => {

    // };

    if (mentee_loading)
        return '';
    else
        return (
            <MenteeDetailsStyled>
                <Grid container spacing={2} display={`flex`} alignItems={`stretch`} justifyContent={`center`}>
                    <Grid item xs={12} md={9}>
                        <PageHeader title={mentee_loading ? <Skeleton animation="wave" height={50} width="40%" /> : (
                            <Stack spacing={1} direction={'row'} display={`flex`} alignItems={`center`} justifyContent={`space-between`} sx={{ width: `100%` }}>
                                <Stack spacing={1} direction={'row'} display={`flex`} alignItems={`center`}>
                                    <Typography variant="h4"><Avatar sx={{ bgcolor: deepPurple[500], mr: 1 }}>{mentee.short_name}</Avatar>{mentee.name}</Typography>
                                    <Chip color={mentee.status_color} label={mentee.status_text} />
                                    {mentee.social_profile && (
                                        <Stack direction={'row'} spacing={1} display={'flex'} alignItems={'flex-start'} className='social-profiles'>
                                            {mentee.social_profile.instagram && <RouteLink to={mentee.social_profile.instagram.url} label={<CardMedia component={`img`} src={instagramIcon} />} target="_blank" />}
                                            {mentee.social_profile.tiktok && <RouteLink to={mentee.social_profile.tiktok.url} label={<CardMedia component={`img`} src={tiktokIcon} />} target="_blank" />}
                                            {mentee.social_profile.linkedin && <RouteLink to={mentee.social_profile.linkedin.url} label={<CardMedia component={`img`} src={linledinIcon} />} target="_blank" />}
                                        </Stack>
                                    )}
                                </Stack>
                                <AbilityCan I='admin_reset_mentee_profile' passThrough={(user.role === 'developer') ? true : false}>
                                    <Button variant='contained' color='error' size='medium' onClick={handleResetMenteeProfile}>Reset Member</Button>
                                </AbilityCan>
                            </Stack>
                        )} />
                        <Stack spacing={1.5}>
                            {mentee.bio && <Typography variant="body2" textAlign={`left`} dangerouslySetInnerHTML={{ __html: mentee.bio }}></Typography>}
                            <Stack spacing={1} direction={`row`}>
                                <Typography variant="body2" textAlign={`left`} display={`flex`} alignItems={`center`} justifyContent={`left`}><Email />&nbsp;{mentee.email}</Typography>
                                <Typography variant="body2" textAlign={`left`} display={`flex`} alignItems={`center`} justifyContent={`left`}><Call />&nbsp;{mentee.phone_number}</Typography>
                                <Typography variant="body2" textAlign={`left`} display={`flex`} alignItems={`center`} justifyContent={`left`}><CalendarMonth />&nbsp;Joined at {mentee.created_at}</Typography>
                            </Stack>
                            <Box>
                                {mentee.menteeship_tags && (mentee.menteeship_tags.sports || mentee.menteeship_tags.self_improvement) && (
                                    <Stack spacing={1} className='menteeship-tags'>
                                        <Typography variant="h6">Menteeship Tags</Typography>
                                        <Stack spacing={1}>
                                            {mentee.menteeship_tags.sports && (
                                                <Stack direction={`row`} spacing={1}>
                                                    <Typography variant="body2" style={{ minWidth: `150px` }}>Sports:&nbsp; </Typography>
                                                    <Stack direction={'row'} spacing={0.5} display={'flex'} alignItems={'flex-start'} className='social-profiles' useFlexGap flexWrap="wrap">
                                                        {(mentee.menteeship_tags.sports.map((menteeship_tag, key) => { return <Chip color='warning' key={key} label={menteeship_tag.name} />; }))}
                                                    </Stack>
                                                </Stack>
                                            )}
                                            {mentee.menteeship_tags.self_improvement && (
                                                <Stack direction={`row`} spacing={1}>
                                                    <Typography variant="body2" style={{ minWidth: `150px` }}>Self improvement:&nbsp; </Typography>
                                                    <Stack direction={'row'} spacing={0.5} display={'flex'} alignItems={'flex-start'} className='social-profiles' useFlexGap flexWrap="wrap">
                                                        {(mentee.menteeship_tags.self_improvement.map((menteeship_tag, key) => { return <Chip color='info' key={key} label={menteeship_tag.name} />; }))}
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                )}
                            </Box>
                            {mentee.about && (
                                <Box>
                                    <Stack spacing={1}>
                                        <Typography variant="h6">About Me</Typography>
                                        <Typography variant="body2" textAlign={`left`} dangerouslySetInnerHTML={{ __html: mentee.about }}></Typography>
                                    </Stack>
                                </Box>
                            )}
                            {mentee.offer && (
                                <Box>
                                    <Stack spacing={1}>
                                        <Typography variant="h6">Offer</Typography>
                                        <Typography variant="body2" textAlign={`left`} style={{ wordWrap: "break-word" }}>{mentee.offer}</Typography>
                                        <Typography variant="body2" textAlign={`left`} display={`flex`} alignItems={`center`}>Network introductions price : <AttachMoney />{mentee.network_introductions_price}</Typography>
                                        <Typography variant="body2" textAlign={`left`} display={`flex`} alignItems={`center`}>Monthly messaging price : <AttachMoney />{mentee.monthly_messaging_price}</Typography>
                                    </Stack>
                                </Box>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className='profile-media'>
                            {mentee.profile_media ?
                                <Carousel>
                                    {mentee.profile_media.map((media, key) => {
                                        let returnMedia = '';
                                        if (media.media_type === 'image')
                                            returnMedia = <CardMedia key={key} component="img" title={mentee.name} src={media.url} onError={e => { e.target.src = defaultMenteeImage; }} loading='lazy' />;
                                        else if (media.media_type === 'video')
                                            returnMedia = <CardMedia key={key} component="video" title={mentee.name} autoPlay src={media.url} onError={e => { e.target.src = defaultMenteeImage; }} />;
                                        return returnMedia;
                                    })}
                                </Carousel>
                                :
                                <CardMedia component="image" title={mentee.name} src={defaultMenteeImage} />
                            }
                        </Box>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                    <DataTable title={`Mentees`} loading={false} columns={columns} data={mentee} setPage={page => handlePage(page)} total="3" per_page="10" />
                </Grid> */}
            </MenteeDetailsStyled>
        );
}

export { MenteeDetails };